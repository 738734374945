// Menu.js
import React, { useState, useEffect } from 'react';
import './Menu.css';
import { fetchMenuData } from '../../util/Api';
import { useNavigate } from 'react-router-dom';
import Submenu from './Submenu';

const Menu = ({ onMenuItemSelect }) => {
    const [menuData, setMenuData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchMenuData()
            .then(data => {
                setMenuData(data);
            })
            .catch(error => {
                console.error('Error fetching menu data:', error);
            });
    }, []);

    return (
        <div className="menu-container">
            <ul className="menu">
                {menuData.map(category => (
                    <li key={category.mainCategoryId} className="menu-item">
                        <p className="menu-link" onClick={() => navigate(`/products/${category.collections}`)}>
                            {category.name}
                        </p>
                        <div className="submenu-container">
                            <Submenu category={category} onMenuItemSelect={onMenuItemSelect} navigate={navigate} />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Menu;
