import React, { useState, useEffect } from 'react';
import { fetchProductsByMenu } from '../../util/Api';
import './ProductHomeScreen.css'; 
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ProductHomeScreen = ({ collect, page, pagesize }) => {
  const [products, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(page); 
  const [selectedProduct, setselectedProduct] = useState(null);
  const dummyUrl = 'https://cdni.iconscout.com/illustration/premium/thumb/no-product-8316266-6632286.png?f=webp'

  const navigate = useNavigate();

  const handleClickOnProduct = (product) => {
    setselectedProduct(product);
  };
  useEffect(() => {
    if (collect) {
      fetchProductsByMenu(collect, currentPage, pagesize) 
        .then(data => {
          setProductData(data);
        })
        .catch(error => {
          console.error('Error fetching products:', error);
        });
    }
  }, [collect, currentPage]); 

  const handleNavigate = (id) => {
    navigate(`/product/${id}`);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1); 
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1); 
  };

  return (
    <>
      {products.length > 0  && collect && <h2 className="collection-title">{collect}</h2>}
      <div className="product-section-container">
        <div className="product-list-container">
          <div className="product-grid-container">
            <div className="home-product-grid">
              {products && products.map((product, index) => (
                <div key={product.id} className="home-product-card" onClick={() => handleClickOnProduct(product)}>
                  <img
                    src={product.images ? product.images[0] :dummyUrl} // Display only the first image if available
                    alt={product.productName}
                    className="home-product-img"
                  />
                  <div className="home-product-details" onClick={() => { handleNavigate(product.id) }}>
                    <p className="home-product-name">{product.productName}</p>
                    <p className="home-product-price">$ {product.price}</p>
                    <p className="home-product-desc">
                      {product.description}
                    </p>
                    <button className="home-add-to-cart-btn">View Product</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {currentPage > 0 && (
            <button className="prev-arrow" onClick={handlePrevPage}><FaArrowLeft /></button>
          )}
          {products.length === pagesize && (
            <button className="next-arrow" onClick={handleNextPage}><FaArrowRight /></button>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductHomeScreen;
