import React from 'react';

const Submenu = ({ category, onMenuItemSelect, navigate }) => {
    return (
        <div className="mega-menu">
            {category && category.subcategories && category.subcategories.map(subcategory => (
                <div key={subcategory.mainCategoryId} className="submenu">
                    <p onClick={() => { navigate(`/products/${subcategory.collections}`); }}>{subcategory.name}</p>
                    <ul>
                        {subcategory.childcategories && subcategory.childcategories.map(childcategory => (
                            <li key={childcategory.mainCategoryId} className="childmenu">
                                <p onClick={() => { onMenuItemSelect(childcategory); navigate(`/products/${childcategory.collections}`); }}>{childcategory.name}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default Submenu;
