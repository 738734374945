// ProductDetailsTables.js
import React from 'react';
import './ProductDetailsTables.css';

const ProductDetailsTables = ({ technicalDetails, additionalInfo }) => {
  return (
    <div className="product-details-tables">
      <div className="technical-details">
        <h3>Technical Details</h3>
        <table>
          <tbody>
            {technicalDetails.map((detail, index) => (
              <tr key={index}>
                <td className='left'>{detail.item}</td>
                <td className='right'>{detail.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="additional-info">
        <h3>Additional Information</h3>
        <table>
          <tbody>
            {additionalInfo.map((info, index) => (
              <tr key={index}>
                <td className='left'>{info.item}</td>
                <td className='right'>{info.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductDetailsTables;
