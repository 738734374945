import { configureStore, createSlice } from "@reduxjs/toolkit"
// import todoSLice from "./stateManage/todoSlice"
const initialState = { value: false}
const userSlices = createSlice({
    name: "user",
    initialState,
    reducers: {
        login: (state, action) => {
            state.value = action.payload
        },
        logout: (state) => {
            state.value = initialState.value
        }
    }

})
const cartInitialState = {value: false}
const cartSlices = createSlice({
    name: "cart",
    initialState : cartInitialState,
    reducers: {
        cartItems: (state, action) => {
            state.value = action.payload
        },
    }

})
const addressInitialState = {value: false}
const addressSlices = createSlice({
    name: "address",
    initialState : addressInitialState,
    reducers: {
        addressUpdate: (state, action) => {
            state.value = action.payload
        },
    }

})
export const { login, logout } = userSlices.actions
export const { cartItems } = cartSlices.actions
export const { addressUpdate } = addressSlices.actions

export const store = configureStore({
    reducer: {
        user: userSlices.reducer ,
        cart: cartSlices.reducer,
        address: addressSlices.reducer
    }
})