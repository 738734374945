import React, { useEffect, useState } from 'react';
import './FilterOptions.css';
import { selectedFilterOptions } from '../../util/Api';

const FilterOptions = ({ genders, colors, sizes, priceRanges, types, brands }) => {
  const [selectedFilters, setSelectedFilters] = useState({});

  const handleCheckboxChange = (category, value) => {
    setSelectedFilters(prevState => {
      if (prevState[category]?.length === 1 && prevState[category].includes(value)) {
        const { [category]: removedKey, ...rest } = prevState;
        return rest;
      }

      return {
        ...prevState,
        [category]: prevState[category]?.includes(value)
          ? prevState[category].filter(item => item !== value)
          : [...(prevState[category] || []), value],
      };
    });
  };
  useEffect(() => {
    if (selectedFilters) {
      selectedFilterOptions(selectedFilters)
        .then(data => {
          console.log(data);
        })
        .catch(error => {
          console.error('Error fetching products:', error);
        });
    }
  }, [selectedFilters])
  return (
    <div className="filters">
      <div className="filter-category">
        <h8>Gender</h8>
        {genders.map((gender, index) => (
          <div key={index}>
            <input type="checkbox" id={`gender-${index}`}
              onChange={() => handleCheckboxChange('genders', gender)}
              checked={selectedFilters.genders?.includes(gender)}
            />
            <label htmlFor={`gender-${index}`}>{gender}</label>
          </div>
        ))}
      </div>
      <div className="filter-category">
        <h8>Colors</h8>
        {colors.map((color, index) => (
          <div key={index}>
            <input type="checkbox" id={`color-${index}`}
              onChange={() => handleCheckboxChange('colors', color)}
              checked={selectedFilters.colors?.includes(color)}
            />
            <label htmlFor={`color-${index}`}>{color}</label>
          </div>
        ))}
      </div>
      <div className="filter-category">
        <h8>Sizes</h8>
        {sizes.map((size, index) => (
          <div key={index}>
            <input type="checkbox" id={`size-${index}`}
              onChange={() => handleCheckboxChange('size', size)}
              checked={selectedFilters.sizes?.includes(size)}
            />
            <label htmlFor={`size-${index}`}>{size}</label>
          </div>
        ))}
      </div>
      <div className="filter-category">
        <h8>Brands</h8>
        {brands.map((brand, index) => (
          <div key={index}>
            <input type="checkbox" id={`size-${index}`}
              onChange={() => handleCheckboxChange('brands', brand)}
              checked={selectedFilters.brands?.includes(brand)}
            />
            <label htmlFor={`size-${index}`}>{brand}</label>
          </div>
        ))}
      </div>
      <div className="filter-category">
        <h8>Types</h8>
        {types.map((type, index) => (
          <div key={index}>
            <input type="checkbox" id={`size-${index}`}
              onChange={() => handleCheckboxChange('types', type)}
              checked={selectedFilters.types?.includes(type)}
            />
            <label htmlFor={`size-${index}`}>{type}</label>
          </div>
        ))}
      </div>
      <div className="filter-category">
        <h8>Price</h8>
        {priceRanges.map((price, index) => (
          <div key={index}>
            <input type="checkbox" id={`size-${index}`}
              onChange={() => handleCheckboxChange('prices', price)}
              checked={selectedFilters.prices?.includes(price)}
            />
            <label htmlFor={`size-${index}`}>{price}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterOptions;
