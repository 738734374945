import React, { useState,useRef } from "react";
import {
    FaEdit,
    FaTrash,
    FaSave,
    FaTimes,
    FaPen,
    FaImage,
    FaCloudUploadAlt,
  } from "react-icons/fa"; // Import edit, delete, save, and cancel icons
const NewReviewForm = ({ productId }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedReview, setEditedReview] = useState({ });
    const [newImages, setNewImages] = useState([]);
    const fileInputRef = useRef(null);
    const handleDeleteImage = (index) => {
        const updatedImages = [...newImages];
        updatedImages.splice(index, 1);
        setNewImages(updatedImages);
      };
      const handleUploadIconClick = () => {
        fileInputRef.current.click();
      };
      const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setNewImages([...newImages, ...files]);
      };
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedReview({ });
    setNewImages([]);
  };

  const handleSaveEdit = () => {
    setIsEditing(false);
    setNewImages([]);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedReview({ ...editedReview, [name]: value });
  };


  return (
    <div>
      <h5>Add a Review</h5>
      <div className="rating">
          {[...Array(5)].map((_, index) => (
            <span
              key={index}
              className={
                index < (isEditing ? editedReview.rating : 4)
                  ? "star-filled"
                  : "star-empty"
              }
              onClick={() =>
                isEditing &&
                setEditedReview({ ...editedReview, rating: index + 1 })
              }
            >
              &#9733;
            </span>
          ))}
        </div>
      <input
                type="text"
                name="review"
                value={editedReview.review}
                onChange={handleInputChange}
              />
              {newImages.map((image, index) => (
        <span key={index}>
          <img
            src={URL.createObjectURL(image)}
            alt="New Image"
            className="user-avatar"
          />
          {isEditing && (
            <FaTimes
              className="delete-image-icon"
              onClick={() => handleDeleteImage(index)}
            />
          )}
            <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
          <div className="add-image" onClick={handleUploadIconClick}>
            <FaCloudUploadAlt className="upload-icon" />
          </div>
        </span>
      ))}
               <FaSave className="edit-icon" onClick={handleSaveEdit} />
                <FaTimes className="cancel-icon" onClick={handleCancelEdit} />
    </div>
  );
};

export default NewReviewForm;
