import React, { useState } from 'react';
import './Registration.css'; // Import the CSS file
import Login from './Login'; // Import the Login component
import { registerUser } from '../../util/Api';
import Otp from './Otp';
import { useNavigate } from 'react-router-dom';
const Registration = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [referredBy, setReferredBy] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [otpComp, setOtpComp] = useState(false);

  // const handleSignInClick = () => {
  //   setShowLogin(true);
  // };
  const navigate = useNavigate()

  const handleRegistration = async (event) => {
    event.preventDefault();

    // Check if registration process is already ongoing
    if (isRegistering) {
      return;
    }

    // Start registration process
    setIsRegistering(true);

    // Reset previous errors
    setEmailError('');
    setPasswordError('');

    // Validate email and password
    if (!validateEmail(email)) {
      setEmailError('Invalid email format');
      setIsRegistering(false);
      return;
    }

    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      setIsRegistering(false);
      return;
    }
    
    try {
      const data = await registerUser(email, password, name, referredBy);
      if(data){
        setOtpComp(true)
      }
    } catch (error) {
      console.error('Registration failed:', error);
    } finally {
      // Reset registration status
      setIsRegistering(false);
    }
  };

  // Function to validate email format
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="amazon-registration-container">
      {!showLogin&& !otpComp && 
        <div className="amazon-registration-box">
          <h2>Create Account</h2>
          <form onSubmit={handleRegistration}>
            <div className="form-group">
              <label>Your name</label>
              <input type="text" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <span className="error-message">{emailError}</span>}
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && <span className="error-message">{passwordError}</span>}
            </div>
            <div className="form-group">
              <label>Referred by</label>
              <input type="text" placeholder="Enter referrer (if any)" value={referredBy} onChange={(e) => setReferredBy(e.target.value)} />
            </div>
            <button type="submit" disabled={isRegistering}>
              {isRegistering ? 'Registering...' : 'Create your Amazon account'}
            </button>
          </form>
          <p>By continuing, you agree to Amazon's Conditions of Use and Privacy Notice.</p>
          <hr />
          <p>Already have an account? <a href="#" onClick={()=> navigate('/login')}>Sign in</a></p>
        </div>
     }
       {/* {showLogin&& !otpComp &&  <Login />} */}
   
      {otpComp&&<Otp userEmail={email}/>}
    </div>
  );
};

export default Registration;
