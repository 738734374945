// NotFound.js
import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const NotFound = () => {
    return (
        <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
                <Typography variant="h4" gutterBottom>
                    404 - Page Not Found
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Sorry, the page you are looking for does not exist. It might have been moved or deleted.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    href="/"
                    sx={{
                        mt: 2,
                        backgroundColor: '#ff9900',
                        '&:hover': {
                            backgroundColor: '#e68a00',
                        },
                    }}
                >
                    Go to Homepage
                </Button>
            </Box>
        </Container>
    );
};

export default NotFound;
