import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import "./ViewCartPage.css";
import {placeOrder,clearCart} from '../../util/Api'
const SelectedAddressAndCart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [address, setAddress] = useState({});
    const userData =  JSON.parse(localStorage.getItem("userData"));   

    const navigate = useNavigate();
    useEffect(() => {
     setCartItems(JSON.parse(localStorage.getItem("confirmed-cart")).orderItems)
     setAddress(JSON.parse(localStorage.getItem("confirmed-cart")).order)


    }, []);  
    const handleProceedToCheckout = () => {
      navigate("/payment");

      // placeOrder(JSON.parse(localStorage.getItem("confirmed-cart")).order, JSON.parse(localStorage.getItem("confirmed-cart")).orderItems)
      //   .then(data => {
      //     localStorage.removeItem("confirmed-cart");
      //    navigate("/payment");
      //   })
      //   .catch(error => {
      //     console.error("Error placing order:", error);
      //   });
      //   if(userData){
      //     clearCart(userData.email)
      //     .then(data => {})
      //     .catch(error => {
      //       console.error("Error clearing cart:", error);
      //     });
      //   }
    
    };
    
    return (
      <div className="view-cart-container">
        <div className="left-section">
          <h2>Your Cart</h2>
          {/* Cart Items */}
          <div className="cart-items-container">
            {/* Mapping through cart items */}
            {cartItems.map((item) => (
              <div key={item.id} className="cart-item">
                <img
                  src={item.imgUrl.split(",")[0]}
                  alt={item.name}
                  className="product-image"
                />
                <div className="product-details">
                  <p className="cart-product-name">{item.name}</p>
                  <p className="product-color">Size: {item.size}</p>
                  <p className="product-color">Qty: {item.quantity}</p>
                </div>
                <p className="product-price">{item.unitprice}</p>
              </div>
            ))}
            {/* End of mapping through cart items */}
          </div>
          {/* <div className="cart-items-container-notes"></div> */}
          <p className="notes">
            The price and availability of items at MemeFabric.tv are won't be
            changed once you placed the order.
          </p>
          <div className="address-list">
              <p>Shipping Address</p>
          <label htmlFor={`address-${address.id}`}>
              <span className="address-name">{address.name}</span>
              <span className="address-details">{`${address.flatNo}, ${address.address1}, ${address.address2},${address.country}, ${address.zip}`}</span>
            </label>
          </div>
          
        </div>
        <div className="right-section">
          <h2>Cart Summary</h2>
          {/* Cart Summary */}
          <div className="cart-summary">
            <p>Total Items: {cartItems.length}</p>
            <p>
              Total Price:{" "}
              {cartItems
                .reduce((total, item) => total + parseFloat(item.unitprice), 0)
                .toFixed(2)}
            </p>
          </div>
          {/* Checkout Button */}
          <button
            className="proceed-to-checkout-btn"
            onClick={handleProceedToCheckout}
          >
            Proceed to Pay
          </button>
        </div>
      </div>
    );
};

export default SelectedAddressAndCart;
