import React, { useState, useEffect } from 'react';
import SingleProductView from './SingleProductView';
import { useLocation } from 'react-router-dom';
import { fetchProduct } from '../../util/Api';
import Loader from '../loader/Loader';

const ProductDetailsPage = () => {
  const [product, setProduct] = useState({});
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productId = location.pathname.split('/')[2];
        const data = await fetchProduct(productId);
        setProduct(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchData();
  }, [location]);
  if (!product) {
    return <div><Loader /></div>;
  }
  return (
    <div>
      <h3>{product.productName}</h3>
      <SingleProductView product={product} />
    </div>
  );
};

export default ProductDetailsPage;
