import React, { useState } from 'react';
import './Otp.css'; 
import { otpConfirmation, resendOtp } from '../../util/Api';

const Otp = ({userEmail}) => {
  const [otp, setOtp] = useState('');
  const [verificationError, setVerificationError] = useState('');
  const [resendError, setResendError] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const handleVerify = async () => {
    setVerificationError('');
    setIsVerifying(true);
    try {
      await otpConfirmation(userEmail,otp);
      // Handle successful verification, e.g., redirect to next page
    } catch (error) {
      setVerificationError('Failed to verify OTP. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  const handleResendOtp = async () => {
    setResendError('');
    setIsResending(true);
    try {
      await resendOtp(userEmail);
      // Handle successful OTP resend, e.g., show success message
    } catch (error) {
      setResendError('Failed to resend OTP. Please try again.');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Verify Your Account</h2>
        <p>A One Time Password (OTP) has been sent to your registered email address.</p>
        <input
          type="text"
          className='otpinput'
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
        {verificationError && <p className="error-message">{verificationError}</p>}
        <button onClick={handleVerify} disabled={isVerifying}>
          {isVerifying ? 'Verifying...' : 'Verify'}
        </button>
        <div className="resend-otp">
          <p>Didn't receive OTP?</p>
          {resendError && <p className="error-message">{resendError}</p>}
          <button onClick={handleResendOtp} disabled={isResending}>
            {isResending ? 'Resending OTP...' : 'Resend OTP'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Otp;
