// OrderDetailsScreen.js
import React, { useState, useEffect } from 'react';
import './OrderDetailsScreen.css';
import { getOrders } from '../../util/Api';

const OrderDetailsScreen = () => {
    const userDatax = localStorage.getItem("userData");
    const userData = JSON.parse(userDatax);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        if (userData && orders.length === 0) {
            getOrders(userData.email, 0, 5)
                .then(data => {
                    setOrders(data);
                })
                .catch(error => {
                    console.error('Error fetching orders:', error);
                });
        }
    }, [userData, orders]);

    return (
        <div className="order-details-container">
            <div className="order-details-header card">
                <h4>Your Order Details</h4>
            </div>
            {orders.map((order) => (
                <div key={order.id} className="order-card">
                    <div className="product-details">
                        <h5>Product Details</h5>
                        <div className="order-items">
                            {order.orderItems.map((item) => (
                                <div key={item.id} className="order-item">
                                    <img src={item.imgUrl} alt={item.name} className="order-item-image" />
                                    <div className="shipping-address">
                                        <p className="order-item-name">{item.name}</p>
                                        <p className="order-item-price">{item.size}</p>
                                        <p className="order-item-price">{item.color}</p>
                                        <p className="order-item-price">${item.unitprice.toFixed(2)}</p>
                                        <p className="order-item-quantity">Quantity: {item.quantity}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="shipping-address">
                            <p>Total Items: ${order.order.price}</p>
                            {/* <p>Total Price: ${order.totalPrice.toFixed(2)}</p> */}
                        </div>
                    </div>
                    <div className="delivery-details">
                        <h5>Delivery Details</h5>
                        <div className='shipping-address'>
                        <p>Order ID: {order.order.ordercode}</p>
                        <p>Order Date: {order.date}</p>
                        </div>
                        <div className="shipping-address">
                            <p>Shipping Address:</p>
                            <p>{order.order.name}</p>
                            <p>{order.order.flatNo}</p>
                            <p>{order.order.address1}</p>
                            <p>{order.order.address2}</p>
                        </div>
                        {/* Add more delivery details as needed */}
                        <div className="order-status">
                            <button className={`status-btn ${order.order.status === 'Delivered' ? 'delivered' : order.order.status === 'Cancelled' ? 'cancelled' : 'other'}`}>{order.order.status}</button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default OrderDetailsScreen;
