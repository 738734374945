// ErrorBoundary.js
import React, { Component } from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { NavigateFunction, useNavigate } from 'react-router-dom';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo
        });
    }

    handleHomeClick = () => {
        this.setState({ hasError: false, error: null, errorInfo: null });
        this.props.navigate('/');
    };

    render() {
        if (this.state.hasError) {
            return (
                <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                        }}
                    >
                        <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
                        <Typography variant="h4" gutterBottom>
                            Something went wrong.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We're sorry, but something went wrong. Please try again later.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleHomeClick}
                            sx={{
                                mt: 2,
                                backgroundColor: '#ff9900',
                                '&:hover': {
                                    backgroundColor: '#e68a00',
                                },
                            }}
                        >
                            Go to Homepage
                        </Button>
                    </Box>
                </Container>
            );
        }

        return this.props.children;
    }
}

const ErrorBoundaryWithNavigate = (props) => {
    const navigate = useNavigate();
    return <ErrorBoundary {...props} navigate={navigate} />;
};

export default ErrorBoundaryWithNavigate;
