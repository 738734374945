// MemeFabricFooter.js
import React from 'react';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="MemeFabric-footer">
      <div className="MemeFabric-footer-content">
        <div className="MemeFabric-footer-section">
          <h5>Get to Know Us</h5>
          <ul>
            <li>About Us</li>
            <li>Careers</li>
            <li>Press Releases</li>
            <li>MemeFabric Cares</li>
            <li>Gift a Smile</li>
          </ul>
        </div>
        <div className="MemeFabric-footer-section">
          <h5>Make Money with Us</h5>
          <ul>
            <li>Sell on MemeFabric</li>
            <li>Sell under MemeFabric Accelerator</li>
            <li>Become an Affiliate</li>
            <li>Fulfilment by MemeFabric</li>
            <li>Advertise Your Products</li>
            <li>MemeFabric Pay on Merchants</li>
          </ul>
        </div>
        <div className="MemeFabric-footer-section">
          <h5>MemeFabric Payment Products</h5>
          <ul>
            <li>MemeFabric Business Card</li>
            <li>Shop with Points</li>
            <li>Reload Your Balance</li>
            <li>MemeFabric Currency Converter</li>
          </ul>
        </div>
        <div className="MemeFabric-footer-section">
          <h5>Let Us Help You</h5>
          <ul>
            <li>MemeFabric and COVID-19</li>
            <li>Your Account</li>
            <li>Your Orders</li>
            <li>Shipping Rates & Policies</li>
            <li>Returns & Replacements</li>
            <li>Manage Your Content and Devices</li>
            <li>MemeFabric Assistant</li>
            <li>Help</li>
          </ul>
        </div>
      </div>
      <div className="MemeFabric-footer-bottom">
        <p>&copy; {new Date().getFullYear()} MemeFabric.tv, Inc. or its affiliates</p>
      </div>
    </footer>
  );
};

export default Footer;
