import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Paper, Box, Divider, Stepper, Step, StepLabel, StepContent, Grid } from '@mui/material';
import { getTracking } from '../../util/Api';

const OrderTracking = () => {
    const [orderDetails, setOrderDetails] = useState(null);
    const [searchInput, setSearchInput] = useState('');

    const handleSearch = async () => {
        try {
            const res = await getTracking(searchInput);

            const stages = Object.keys(res).reverse().map((key, index) => ({
                label: key,
                completed: res[key],
            }));

            setOrderDetails({
                deliveryStages: stages,
            });
        } catch (error) {
            console.error("Error fetching tracking data:", error);
        }
    };

    const activeStep = orderDetails ? orderDetails.deliveryStages.findIndex(stage => !stage.completed) : 0;

    return (
        <Container maxWidth="lg" sx={{ pt: 4 }}>
            <Box display="flex" justifyContent="center" mb={3} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                <TextField
                    label="Enter order ID or tracking ID"
                    variant="outlined"
                    fullWidth
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    sx={{ mb: { xs: 2, sm: 0 }, mr: { sm: 1 }, fontSize: '1.1rem' }}
                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                />
                <Button variant="contained" sx={{
                    backgroundColor: '#CF142B',
                    '&:hover': {
                        backgroundColor: '#e68a00',
                    },
                    fontSize: '1.1rem'
                }} onClick={handleSearch}>
                    Search
                </Button>
            </Box>
            {orderDetails && (
                <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>Order Details</Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h6" sx={{ mt: 2, mb: 2, fontSize: '1.3rem' }}>Delivery Stages:</Typography>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {orderDetails.deliveryStages.map((stage, index) => (
                                        <Step key={index} completed={stage.completed} 
                                            sx={{ 
                                                "& .MuiStepIcon-root.Mui-completed": { color: '#CF142B !important' },
                                                "& .MuiStepIcon-root.Mui-active": { color: '#CF142B !important' },
                                                "& .MuiStepLabel-label.Mui-completed": { fontWeight: 'bold', color: '#333', fontSize: '1.2rem' },
                                                "& .MuiStepLabel-label.Mui-active": { fontWeight: 'bold', color: '#333', fontSize: '1.2rem' },
                                            }}>
                                            <StepLabel>{stage.label}</StepLabel>
                                            <StepContent>
                                                {index === activeStep + 1 ? <Typography color="secondary" sx={{ fontSize: '1.1rem' }}>Next step in process</Typography> : <Typography sx={{ fontSize: '1.1rem' }}>{stage.label}</Typography>}
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <img src="https://img.freepik.com/free-vector/post-service-tracking-abstract-concept-vector-illustration-parcel-monitor-track-trace-your-shipment-package-tracking-number-express-delivery-online-shopping-mail-box-abstract-metaphor_335657-1777.jpg"
                                alt="Product" style={{ width: '100%', borderRadius: '8px' }} />
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </Container>
    );
};

export default OrderTracking;
