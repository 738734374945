import React, { useState ,useEffect} from 'react';
import AddressList from './AddressList';
import { getAddress } from '../../util/Api';
import { useSelector } from 'react-redux';
const DummyAddressList = () => {
    const[addresses,setAddress]=useState([])
    const [selectedAddress, setSelectedAddress] = useState(null);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const addressUpdate = useSelector((state) => state.address.value)
    useEffect(() => {
        if (userData ) {
            getAddress(userData.userId, 0, 3)
                .then(data => {
                    setAddress(data);
                })
                .catch(error => {
                    console.error('Error fetching orders:', error);
                });
        }
    }, [ addressUpdate ]);


    const handleSelectAddress = (addressId) => {
        setSelectedAddress(addressId);
    };

    return (
        <div>
            {userData?(<AddressList
                addresses={addresses}
                selectedAddress={selectedAddress}
                onSelectAddress={handleSelectAddress}
                setAddress={setAddress}
                userId={userData.userId}
            />):(<AddressList/>)}
        </div>
    );
};

export default DummyAddressList;
