import React, { useState, useEffect } from "react";
import ProductHomeScreen from '../product/ProductHomeScreen';
import './Style.css';
import BannerSlideshow from "../slide-show/BannerSlideshow";
import { fetchCollection } from '../../util/Api';
import { useNavigate } from "react-router-dom";

const HomeScreen = ({ rowData }) => {
  const [firstRange, setFirstRange] = useState([]);
  const [secondRange, setSecondRange] = useState([]);
  const [randomCollections, setRandomCollections] = useState([]);

  useEffect(() => {
    fetchCollection(0, 16)
      .then(col => {
        setFirstRange(col);
      })
      .catch(error => {
        console.error('Error fetching first range data:', error);
      });
  }, []);

  useEffect(() => {
    fetchCollection(1, 16)
      .then(col => {
        setSecondRange(col);
      })
      .catch(error => {
        console.error('Error fetching second range data:', error);
      });
  }, []);

  useEffect(() => {
    const getRandomCollectionNames = () => {
      const collections = [];
      if (firstRange.length > 0 && secondRange.length > 0) {
        for (let i = 0; i < 5; i++) {
          const randomIndex = Math.floor(Math.random() * 2);
          const randomRange = randomIndex === 0 ? firstRange : secondRange;
          const randomCollection = randomRange[Math.floor(Math.random() * randomRange.length)].collectionList[Math.floor(Math.random() * randomRange.length)].name;
          collections.push(randomCollection);
        }
      }
      return collections;
    };

    const randomCollections = getRandomCollectionNames();
    setRandomCollections(randomCollections);
  }, [firstRange, secondRange]);

  const navigate = useNavigate()

  return (
    <>
      <BannerSlideshow />
      <div className="container">
        <div className="row row-cols-md-2 row-cols-lg-4 g-3">
          {firstRange.map((row, index) => (
            <div key={index} className="col mb-3" style={{display:"flex", justifyContent:'center'}}>
              <div className="collection-card p-3">
                <div className="row row-cols-2 g-3">
                  {row.collectionList.map((col, colIndex) => (
                    <div key={colIndex} className="col" style={{cursor:'pointer'}} onClick={() => { navigate(`/products/${col.name}`); }}>
                      <img src={col.url} className="img-fluid" alt={`Image ${colIndex + 1}`} />
                      <p>{col.collectionDisplayName}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row row-cols-md-2 row-cols-lg-4 g-3">
          {secondRange.map((row, index) => (
            <div key={index} className="col mb-3" style={{display:"flex", justifyContent:'center'}}>
              <div className="collection-card p-3">
                <div className="row row-cols-2 g-3">
                  {row.collectionList.map((col, colIndex) => (
                    <div key={colIndex} className="col" style={{cursor:'pointer'}} onClick={() => { navigate(`/products/${col.name}`); }}>
                      <img src={col.url} className="img-fluid" alt={`Image ${colIndex + 1}`} />
                      <p>{col.collectionDisplayName}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        {randomCollections && randomCollections.map((item) => (
          <ProductHomeScreen collect={item} page={0} pagesize={5} />
        ))
        }
      </div>
    </>
  );
};

export default HomeScreen;
