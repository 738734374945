import React, { useState, useRef } from "react";
import "./CustomerReview.css"; // Import CSS file for styling
import {
  FaEdit,
  FaTrash,
  FaSave,
  FaTimes,
  FaPen,
  FaImage,
  FaCloudUploadAlt,
} from "react-icons/fa"; // Import edit, delete, save, and cancel icons

const CustomerReview = ({ review, onEditReview, onDeleteReview }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedReview, setEditedReview] = useState({ ...review });
  const [newImages, setNewImages] = useState([]);
  const fileInputRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedReview({ ...review });
    setNewImages([]);
  };

  const handleSaveEdit = () => {
    onEditReview(editedReview);
    setIsEditing(false);
    setNewImages([]);
  };

  const handleDelete = () => {
    onDeleteReview(review.id);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedReview({ ...editedReview, [name]: value });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setNewImages([...newImages, ...files]);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...newImages];
    updatedImages.splice(index, 1);
    setNewImages(updatedImages);
  };

  const handleSaveImage = () => {
    // Implement save image functionality here
    console.log("Save image:", newImages);
  };

  const handleUploadIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="customer-review">
      <div className="review-header">
        <div className="user-info">
          <p className="user-name">
            {isEditing ? (
              <input
                type="text"
                name="review"
                value={editedReview.review}
                onChange={handleInputChange}
              />
            ) : (
              review.review
            )}
          </p>
          <p className="review-date">{review.date}</p>
        </div>
        <div className="review-actions">
          {userData && userData.email === review.userId ? (
            // Render edit and delete icons only if user is logged in and review user ID matches login email ID
            isEditing ? (
              <>
                <FaSave className="edit-icon" onClick={handleSaveEdit} />
                <FaTimes className="cancel-icon" onClick={handleCancelEdit} />
              </>
            ) : (
              <>
                <FaPen className="edit-icon" onClick={handleEdit} />
                <FaTrash className="delete-icon" onClick={handleDelete} />
              </>
            )
          ) : null}
        </div>

        <div className="rating">
          {[...Array(5)].map((_, index) => (
            <span
              key={index}
              className={
                index < (isEditing ? editedReview.rating : review.rating)
                  ? "star-filled"
                  : "star-empty"
              }
              onClick={() =>
                isEditing &&
                setEditedReview({ ...editedReview, rating: index + 1 })
              }
            >
              &#9733;
            </span>
          ))}
        </div>
      </div>
      {review.images &&
        review.images.split(",").map((imgx, index) => (
          <span key={index}>
            <img src={imgx} alt="User Avatar" className="user-avatar" />
            {isEditing && (
              <FaTimes
                className="delete-image-icon"
                onClick={() => handleDeleteImage(index)}
              />
            )}
          </span>
        ))}
      {newImages.map((image, index) => (
        <span key={index}>
          <img
            src={URL.createObjectURL(image)}
            alt="New Image"
            className="user-avatar"
          />
          {isEditing && (
            <FaTimes
              className="delete-image-icon"
              onClick={() => handleDeleteImage(index)}
            />
          )}
        </span>
      ))}
      {isEditing && (
        <>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
          <div className="add-image" onClick={handleUploadIconClick}>
            <FaCloudUploadAlt className="upload-icon" />
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerReview;
