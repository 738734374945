import React, { useState, useEffect } from 'react';
import { fetchProductsByMenu } from '../../util/Api';
import './Styles.css'; // Import your CSS file
import { useLocation } from 'react-router-dom';
import Loader from '../loader/Loader';
import FilterOptions from './FilterOptions';
import { useNavigate } from 'react-router-dom';

const ProductList = () => {
  const [products, setProductData] = useState([]);
  const [selectProduct, setSelectProduct] = useState(null);
  const location = useLocation()
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/product/${id}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const selectedMenuItem = location.pathname.split('/')[2]
    if (selectedMenuItem) {
      setSelectProduct(null);
      fetchProductsByMenu(selectedMenuItem, 0, 10)
        .then(data => {
          setProductData(data);
        })
        .catch(error => {
          console.error('Error fetching products:', error);
        });
    }
  }, [location]);
  const handleProductClick = (product) => {
    setSelectProduct(product);
  };
  if(!products){
    return <Loader />
  }

  return (
    <div className="product-list-container">
      {!selectProduct &&
        
          <FilterOptions
        genders={['Male', 'Female']}
        colors={['Red', 'Blue', 'Green']}
        sizes={['Small', 'Medium', 'Large']}
        priceRanges={['$0 - $50', '$50 - $100', '$100 - $200']}
        types={['T-Shirt', 'Jeans', 'Shoes']}
        brands={['Nike', 'Adidas', 'Puma']}
      />
      }
      {!selectProduct &&
        <div className="m-product-grid">
          {products.map((product, index) => (
            <div key={product.id} className="m-product-card" onClick={() => handleNavigate(product.id)}>
              <img
                src={product.images[0]} // Display only the first image
                alt={product.productName}
                className="m-product-img"
              />
              <div className="m-product-details">
                <p className="m-product-name">{product.productName}</p>
                <p className="m-product-price">₹ {product.price}</p>
                <p className="m-product-desc">
                  {product.description}
                </p>
                <button className="m-add-to-cart-btn">View Product</button>
              </div>
            </div>
          ))}
        </div>}
      {/* {selectProduct && <ProductDetailsPage selectedProduct={selectProduct} />} */}
    </div>
  );
};

export default ProductList;
