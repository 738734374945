import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import './Checkout.css';
import { clearCart, placeOrder, stripePay, viewCart } from '../../util/Api';
import Loader from "../loader/Loader"
import { useDispatch } from 'react-redux';
import { cartItems } from '../../util/Store';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const uuid = localStorage.getItem("uuid");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch()

  const success = () => {
    placeOrder(JSON.parse(localStorage.getItem("confirmed-cart")).order, JSON.parse(localStorage.getItem("confirmed-cart")).orderItems)
      .then((res) => {
        localStorage.removeItem("confirmed-cart");
        localStorage.removeItem("cartItems");
        localStorage.removeItem('uuid');
        dispatch(cartItems(true));
        navigate("/success");
      }).then(()=>{
        dispatch(cartItems(false))
      })
      .catch(error => {
        console.error("Error placing order:", error);
      });

    if (userData) {
      clearCart(userData.email)
        .then(data => {
          dispatch(cartItems(true));
        })
        .catch(error => {
          console.error("Error clearing cart:", error);
        });
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setErrorMessage('Stripe has not loaded yet.');
      setLoading(false);
      return;
    }

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setErrorMessage(submitError.message);
        setLoading(false);
        return;
      }

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: success()//`${window.location.origin}/success`,
        },
        redirect: 'if_required',
      });

      if (error) {
        setErrorMessage(error.message);
        setLoading(false);
        navigate('/failure');
        return;
      }

      // navigate('/success');
    } catch (error) {
      console.error('Payment Error:', error);
      setErrorMessage('Payment Failed');
      navigate('/failure');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '5em 0' }}>
      <form onSubmit={handleSubmit} className="checkout-form">
        <PaymentElement className="payment-element" />
        <button type="submit" disabled={!stripe || !elements || loading} className="checkout-button">
          {loading ? 'Processing...' : 'Pay'}
        </button>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </form>
    </div>
  );
};

const stripePromise = loadStripe('pk_test_51KkUVZJAvb7prcutR1BIs51QQMPXNY7AIFrcoR12f4Fo5XrhYiJIUJzmjc4yBjuSo7SwjJMMZTY4IwweUlEST8Cs00L4c7pY6Q');

const Checkout = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [price, setPrice] = useState(0)
  const uuid = localStorage.getItem("uuid");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [cartItems, setCartItems] = useState([])
  useEffect(() => {
    const id = userData ? userData.email : uuid;
    viewCart(id)
      .then((data) => {
        setCartItems(data.orderItems);
        const total = data.orderItems.reduce((acc, curr) => { return acc + curr.unitprice }, 0)
        setPrice(total)
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, []);

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const { client_secret } = await stripePay({
          amount: price,
          currency: 'usd',
          method: 'card',
        });
        setClientSecret(client_secret);
      } catch (error) {
        console.error('Failed to fetch client secret:', error);
      }
    };

    if (price > 0) {
      fetchClientSecret();
    }
  }, [price]);

  const options = {
    clientSecret: clientSecret,
    appearance: {
      theme: 'flat',
      variables: {
        colorPrimary: '#6772e5',
      },
      rules: {
        '.Label': {
          color: '#32325d',
          fontWeight: '500',
        },
      },
    },
  };

  return clientSecret ? (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  ) : (
    <div><Loader /></div>
  );
};

export default Checkout;
