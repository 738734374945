import React, { useEffect, useState } from 'react';
import { searchproduct } from '../../util/Api';
import { useNavigate } from 'react-router-dom';
import './SearchResult.css';
import logo from '../../assets/logo.png';

const SearchResults = ({ searchQuery }) => {
  const [products, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/product/${id}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const query = searchParams.get('query');

    if (products.length === 0) {
      setIsLoading(true); // Set loading state to true when fetching products
      searchproduct(query)
        .then(data => {
          setProductData(data);
        })
        .catch(error => {
          console.error('Error fetching products:', error);
        })
        .finally(() => {
          setIsLoading(false); // Set loading state to false after fetching products
        });
    }
  }, [searchQuery]);

  return (
    <div className="search-results">
      {isLoading ? ( // Display loading state while fetching products
        <div className="loading-container">
          <p>Loading...</p>
        </div>
      ) : products.length > 0 ? (
        <div className="m-product-grid">
          {products.map((product, index) => (
            <div key={product.id} className="m-product-card" onClick={() => handleNavigate(product.id)}>
              <img
                src={product.images[0]} // Display only the first image
                alt={product.productName}
                className="m-product-img"
              />
              <div className="m-product-details">
                <p className="m-product-name">{product.productName}</p>
                <p className="m-product-price">₹ {product.price}</p>
                <p className="m-product-desc">
                  {product.description}
                </p>
                <button className="m-add-to-cart-btn">Add to Cart</button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-products-container">
          <div className="no-products">
            <img src={logo} alt="No products" className="no-products-image" />
            <h4 className="no-product-title">Hey, It feels so light!</h4>
            
            <button className="add-from-wishlist-btn">
              SEARCH WITH DIFFERENT PRODUCTS
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
