import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';

const Success = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="#f5f5f5"
      p={4}
    >
      <CheckCircleOutlineIcon color="success" style={{ fontSize: 100 }} />
      <Typography variant="h4" component="h1" gutterBottom>
        Payment Successful!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Thank you for your purchase. Your order has been successfully placed.
      </Typography>
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={{
          mt: 2,
          width:'20%',
          backgroundColor: '#CF142B',
          '&:hover': {
            backgroundColor: '#e68a00',
          },
        }}
      >
        Continue Shopping
      </Button>
    </Box>
  );
};

export default Success;
