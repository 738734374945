// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/header/Header';
import Menu from './components/category/Menu';
import HomeScreen from './components/home-screen-grids/HomeScreen';
import ProductList from './components/product/ProductList';
import Login from './components/account/Login';
import Registration from './components/account/Registration';
import Footer from './components/footer/Footer';
import { getTokenFromApi } from './util/Api';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { store } from './util/Store';
import ProductDetailsPage from './components/single-product/ProductDetailsPage';
import ViewCartPage from './components/cart/ViewCartPage'
import OrderDetailsScreen from './components/orders/OrderDetailsScreen'
import DummyAddressList from './components/address/DummyAddressList'
import OrderTracking from './components/tracking/OrderTracking'
import SelectedAddressAndCart from './components/cart/SelectedAddressAndCart';
import Wishlist from './components/wishlist/Wishlist';
import SearchResults from './components/product/SearchResults'
import Checkout from './components/cart/Checkout';
import Success from './components/constants/Success';
import Failure from './components/constants/Failure';
import ErrorBoundary from './components/constants/ErrorBoundry';
import NotFound from './components/constants/NotFound';

function App() {

    const [token, setToken] = useState(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const [loginCmd, setLoginCmd] = useState(null);
    const [regisyterCmd, setRegisterCmd] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const handleLogin = () => {
        setLoginCmd(true);
        setRegisterCmd(false);
    };
    // Function to calculate cart count
    const getCartCount = () => {
        let count = 0;
        cartItems.forEach(item => {
            count += item.quantity;
        });
        return count;
    };


    useEffect(() => {
        const storedToken = localStorage.getItem('token');

        if (storedToken) {
            // Token already exists in local storage
            setToken(storedToken);
        } else {
            // Fetch token from API
            getTokenFromApi()
                .then(newToken => {
                    setToken(newToken);
                    // Store token in local storage for future use
                    localStorage.setItem('token', newToken);
                })
                .catch(error => {
                    console.error('Error getting token:', error);
                });
        }
    }, []);
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Header cartCount={getCartCount()} loginPressed={handleLogin} />
                <ErrorBoundary>
                <Routes>
                    <Route path='/' element={<HomeScreen  />} />
                    <Route path='/login' element={<Login registrationPressed={setRegisterCmd} />} />
                    <Route path='/registration' element={<Registration />} />
                    <Route path='/product/:id' element={<ProductDetailsPage />} />
                    <Route path='/products/:productName' element={<ProductList />} />
                    <Route path='/cart' element={<ViewCartPage />} />
                    <Route path='/order' element={<OrderDetailsScreen/>} />
                    <Route path='/address' element={<DummyAddressList/>} />
                    <Route path='/tracking' element={<OrderTracking/>} />
                    <Route path='/confirmation' element={<SelectedAddressAndCart/>} />
                    <Route path="/wishlist" element={<Wishlist/>} />
                    <Route path='/search-result' element={<SearchResults/>}/>
                    <Route path='/payment' element={<Checkout/>}/>
                    <Route path="/success" element={<Success />} />
                    <Route path="/failure" element={<Failure />} />
                    <Route path="*" element={<NotFound />} /> 
                </Routes>
                </ErrorBoundary>
                <Footer />
            </BrowserRouter>
        </Provider>
    );
}

export default App;
