import React, { useState, useEffect } from 'react';
import './Header.css';
import { FaCartPlus, FaSearch } from "react-icons/fa";
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MdFavoriteBorder } from "react-icons/md";
import { MdPerson } from "react-icons/md";
import { login } from '../../util/Store';
import Menu from '../category/Menu';

const Header = ({ loginPressed }) => {
  const [user, setUser] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const handleLogincmd = () => {
    loginPressed('pressed');
  };
  const [cartCount, setCartCount] = useState([])
  const userData = useSelector((state) => state.user.value)
  const cartData = useSelector((state) => state.cart.value)
  useEffect(() => {
    setCartCount(JSON.parse(localStorage.getItem("cartItems")) || []);

  }, [cartData])
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    setUser(JSON.parse(userData));
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [userData]);

  const handleOutsideClick = (event) => {
    if (!event.target.closest('.dropdown')) {
      setShowDropdown(false);
    }
  };
  const dispatch = useDispatch()
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleLogoutClick = () => {
    localStorage.removeItem("userData");
    dispatch(login(true))
  }
  const handleSearch = () => {
    const url = `/search-result?query=${encodeURIComponent(searchQuery)}`;
    window.location.href = url;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div>
      <header className="header">
        <div className="header__logo">
          <Link to='/'> <img
            src={logo}
            alt="Amazon Logo"
          /></Link>
        </div>
        <div style={{ position: "relative" }}>
          <Menu onMenuItemSelect={setSelectedMenuItem} />
        </div>
        <div className="header_search">
          <input
            type="text"
            placeholder="Search for products, brands and more"
            value={searchQuery}
            className='header_search_input'
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyPress} // Call handleSearch when Enter key is pressed
          />
          <FaSearch className="search-icon" onClick={handleSearch} /> {/* Call handleSearch when icon is clicked */}
        </div>

        <nav className="header__nav">
          {!user && (
            <Link to="/login" className='header__nav_li' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <MdPerson style={{ width: 50, color: 'white', height: '1.3rem' }} />
              <span onClick={handleLogincmd} className='header_profile_name'>Accounts</span>
            </Link>
          )}
          {user && (
            <div className="dropdown" onClick={toggleDropdown}>
              <div className='header__nav_li' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <MdPerson style={{ width: 50, color: 'white', height: '1.3rem' }} />
                <span>{user.name}</span>
              </div>
              {showDropdown && (
                <div className="dropdown-content">
                  <span>Account Settings</span>
                  <Link to="/order" style={{ textDecoration: 'none' }}>
                    <span>Orders</span>
                  </Link>
                  <Link to="/address" style={{ textDecoration: 'none' }}>
                    <span>Addresses</span>
                  </Link>
                  <Link to="/tracking" style={{ textDecoration: 'none' }}>
                    <span>Tracking</span>
                  </Link>
                  <span onClick={() => handleLogoutClick()}>Logout</span>
                </div>
              )}
            </div>
          )}
          <Link to="/wishlist" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', flexDirection: 'column' }} className='header__nav_li'>
            <MdFavoriteBorder style={{ width: 50, color: 'white', height: '1.3rem' }} />
            <span className='header_profile_name'>wishlist</span>
          </Link>
          <Link to="/cart" className='cart_contain header__nav_li'  >
            <FaCartPlus style={{ width: 50, color: 'white', height: '1.3rem' }} />
            <span className="cart-count" style={{ color: 'white' }}>{cartCount.length}</span>
            <span className='header_profile_name'>Cart</span>
          </Link>
        </nav>
        {/* <div className="header__cart">
          <div className="cart-icon">
            <Link to="/cart" style={{ textDecoration: 'none' }}>
              <FaCartPlus style={{ width: 50, color: 'white', height: '1.5rem' }} />
              <span className="cart-count" style={{ color: 'white' }}>{cartCount}</span>
            </Link>
          </div>
        </div> */}
      </header>
    </div>
  );
};

export default Header;
