import React, { useEffect, useState } from 'react';
import { Typography, Grid, Card, CardActionArea, CardContent, CardMedia, Button } from '@mui/material';
import logo from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import "./Wishlist.css"
import Loader from '../loader/Loader';

const Wishlist = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const wishlistData = JSON.parse(localStorage.getItem('profile'))?.wishList;
  const navigate = useNavigate();

  useEffect(() => {
    if (wishlistData) {
      setProducts(wishlistData);
      setIsLoading(false);
    }else{
      setIsLoading(false);
    }
  }, [wishlistData]);

  const handleNavigate = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div className="wishlist-container">
      {userData && <Typography variant="h5" gutterBottom>Wishlist Products</Typography>}
      {isLoading ? (
        <Typography><Loader /></Typography>
      ) : userData ? (
        products.length > 0 ? (
          <Grid container spacing={3}>
            {products.map((product) => (
              <Grid item xs={12} sm={6} md={4} key={product.id}>
                <Card className="product-card">
                  <CardActionArea onClick={() => handleNavigate(product.id)}>
                    <CardMedia
                      component="img"
                      image={product.images[0]}
                      alt={product.productName}
                      className="product-media"
                    />
                    <CardContent>
                      <Typography variant="h6" className="product-name">{product.productName}</Typography>
                      <Typography variant="body1" color="textSecondary">{product.description}</Typography>
                      <Typography variant="h6" className="product-price">₹ {product.price}</Typography>
                    </CardContent>
                  </CardActionArea>
                  <Button variant="contained" color="primary" fullWidth>Add to Cart</Button>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <div className="no-products-container">
            <img src={logo} alt="No products" className="no-products-image" />
            <Typography variant="h5" gutterBottom>Hey, It feels so light!</Typography>
            <Typography paragraph>There is nothing in your wishlist. Let's add some items.</Typography>
            <Button variant="contained" className="add-items-button" onClick={()=>{navigate('/')}}>ADD ITEMS FROM CATALOGUE</Button>
          </div>
        )
      ) : (
        <div className="login-message">
          <Typography sx={{cursor:'pointer'}} onClick={()=>{navigate('/login')}}>Please login to view your wishlist.</Typography>
        </div>
      )}
    </div>
  );
};

export default Wishlist;
