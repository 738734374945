import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './BannerSlideshow.css'; // Import your CSS file for custom styling
import { fetchSlider } from '../../util/Api';

const BannerSlideshow = () => {
    const [slides, setSlidesData] = useState([ 'https://images-eu.ssl-images-amazon.com/images/G/31/img21/OHL/Gateway/April/Clearance/store/CLEARANCE-STORE-gw-hero_pc_2x._CB561562296_.jpg',
        'https://images-eu.ssl-images-amazon.com/images/G/31/img22/OHL/Gateway/April/Clearance/store/CLEARANCE-STORE-gw-hero_pc_2x._CB561562296_.jpg',
        'https://images-eu.ssl-images-amazon.com/images/G/31/img21/OHL/Gateway/April/Clearance/store/CLEARANCE-STORE-gw-hero_pc_2x._CB561562296_.jpg']);

    const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000, // Adjust autoplay speed as needed
        speed: 1000, // Transition speed between slides
        slidesToShow: 1, // Show one slide at a time
        slidesToScroll: 1,
        
    };

    // useEffect(() => {
    //     fetchSlider()
    //         .then(imgs => {
    //             setSlidesData(imgs)
    //         })
    //         .catch(error => {
    //             console.error('Error fetching slider data:', error);
    //         });
    // }, []);
    return (
        <div className="banner-slideshow-container">
            <Slider {...settings}>
                {slides.map((image, index) => (
                    <div key={index}>
                        <img src={image} alt={`Banner ${index + 1}`} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};
// "https://images-eu.ssl-images-amazon.com/images/G/31/img21/OHL/Gateway/April/Clearance/store/CLEARANCE-STORE-gw-hero_pc_2x._CB561562296_.jpg"
export default BannerSlideshow;
